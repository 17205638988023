import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Table, Form, FormGroup, Label, Button } from 'reactstrap';
import Widget from '../../components/Widget/Widget';
import './CraPage.scss';
import { getCurrentInfo, getMissionById, getCraRecords, saveCra } from "../../services/configService";
import showToast from "../../components/NotificationToast/toastUtils";
import {useDispatch} from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";

const getDaysInMonth = (month, year) => {
    const date = new Date(year, month - 1, 1);
    const days = [];
    while (date.getMonth() === month - 1) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

const Cra = () => {
    const [client, setClient] = useState("");
    const [missionsList, setMissionsList] = useState([]); // List of missions
    const [mission, setMission] = useState(""); // Selected mission ID
    const [month, setMonth] = useState(new Date().getMonth() + 1); // Set the current month
    const [year, setYear] = useState(new Date().getFullYear()); // Set the current year
    const [daysInMonth, setDaysInMonth] = useState([]);
    const [userData, setUserData] = useState(null); // State for storing user info
    const [isCraExisting, setIsCraExisting] = useState(false); // Indique si le CRA existe déjà
    const dispatch = useDispatch();

    const fetchData = async () => {
        try {
            // Récupérer les informations de l'utilisateur depuis l'API
            const res = await getCurrentInfo();
            setUserData(res.data);
            if (res.data && res.data.employeeId) {
                const res2 = await getMissionById(res.data.employeeId);
                setMissionsList(res2.data); // Store the list of missions
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array means this runs once when the component mounts

    useEffect(() => {
        const daysArray = getDaysInMonth(month, year).map(date => ({
            date,
            serviceDay: 0,
            offDay: 0,
            onSiteTelework: 'O'
        }));
        setDaysInMonth(daysArray);
    }, [month, year]);

    const fetchCraData = async (missionId, month, year) => {
        try {
            const res = await getCraRecords(missionId, month, year);
            if (res.data && res.data.days && res.data.days.length > 0) {
                setDaysInMonth(res.data.days);  // Charger les données CRA existantes
                setIsCraExisting(true);  // Griser les champs si un CRA existe
            } else {
                setIsCraExisting(false);  // Autoriser la modification si aucun CRA
            }
        } catch (error) {
            console.error("Erreur lors de la récupération du CRA", error);
        }
    };

    const toggleOnSiteTelework = (index) => {
        const newDaysInMonth = [...daysInMonth];
        newDaysInMonth[index].onSiteTelework = newDaysInMonth[index].onSiteTelework === 'R' ? 'O' : 'R';
        setDaysInMonth(newDaysInMonth);
    };

    const handleServiceDayChange = (index, value) => {
        if (value === '') value = 'null';
        if (value === 'null') value = '0';
        if (value === '01' || value === '10') value = '1';

        if (value === '0' || value === '1' || value === '0.5') {
            const newDaysInMonth = [...daysInMonth];
            newDaysInMonth[index].serviceDay = parseFloat(value);
            setDaysInMonth(newDaysInMonth);
        }
    };

    // Fonction pour calculer le total des jours prestés
    const calculateTotalServiceDays = () => {
        return daysInMonth.reduce((total, day) => total + day.serviceDay, 0);
    };

    const totalServiceDays = calculateTotalServiceDays();

    const handleSubmit = async () => {
        const craData = {
            missionId: mission, // Use the selected mission ID
            month,
            year,
            days: daysInMonth.map(day => ({
                serviceDay: day.serviceDay,
                offDay: day.offDay,
                onSiteTelework: day.onSiteTelework,
                date: day.date.toISOString().split('T')[0] // Format ISO pour les dates
            })),
            totalServiceDays
        };
        dispatch(startLoading());
        try {
            await saveCra(craData); showToast('Le CRA a été enregistré avec succès.');
        } catch (error) {
            showToast('Le CRA n\'a pas pu être enregistré.', "error");
        } finally {
            dispatch(stopLoading());
            fetchData();
        }
    };

    return (
        <Widget>
            <Form>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <Label>Mission</Label>
                            <Input
                                type="select"
                                value={mission} // Selected mission ID
                                onChange={(e) => {
                                    setMission(e.target.value);
                                    fetchCraData(e.target.value, month, year); // Appeler pour récupérer le CRA lors du changement de mission
                                }}
                            >
                                <option value="">Sélectionner une mission</option>
                                {missionsList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.label}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Mois</Label>
                            <Input type="select" value={month} onChange={(e) => setMonth(parseInt(e.target.value))}>
                                {Array.from({ length: 12 }, (_, i) => i + 1).map(m => (
                                    <option key={m} value={m}>{m}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Année</Label>
                            <Input type="select" value={year} onChange={(e) => setYear(parseInt(e.target.value))}>
                                {Array.from({ length: 10 }, (_, i) => 2024 + i).map(y => (
                                    <option key={y} value={y}>{y}</option>
                                ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table bordered className="cra-table">
                            <thead>
                            <tr>
                                <th>Semaine</th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index}>{getWeekNumber(day.date)}</th>
                                ))}
                                <th>Total</th>
                            </tr>
                            <tr>
                                <th>Jours</th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>
                                        {day.date.getDate()}
                                    </th>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {daysInMonth.map((day, index) => (
                                    <th key={index} className={isWeekend(day.date) ? 'weekend' : 'Notweekend'}>
                                        {getDayOfWeek(day.date)}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Jours prestés</td>
                                {daysInMonth.map((day, index) => (
                                    <td key={index} className={isWeekend(day.date) ? 'weekend' : ''}>
                                        <input
                                            className="cra_input"
                                            type="text"
                                            value={day.serviceDay}
                                            onChange={(e) => handleServiceDayChange(index, e.target.value)}
                                            disabled={isCraExisting} // Désactiver si le CRA existe
                                        />
                                    </td>
                                ))}
                                <td>{totalServiceDays}</td>
                            </tr>
                            </tbody>
                        </Table>
                        <footer className="text-center my-4">
                            <p>Par le présent document, j'atteste que les travaux ont été effectués conformément aux règles et déclare leur validité. Bon pour facturation.</p>
                        </footer>
                    </Col>
                </Row>
                <Button className="text-right my-4" onClick={handleSubmit} disabled={isCraExisting}>
                    Enregistrer CRA
                </Button>
            </Form>
        </Widget>
    );
};

function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

function getDayOfWeek(date) {
    const daysOfWeek = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    return daysOfWeek[date.getDay()];
}

function isWeekend(date) {
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Dimanche, 6 = Samedi
}

export default Cra;
