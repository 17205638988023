// -- React and related libs
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Typography from "../../pages/typography/Typography";
import Notifications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
import Charts from "../../pages/uielements/charts/Charts";
import Icons from "../../pages/uielements/icons/IconsPage";
import Maps from "../../pages/uielements/maps/google/GoogleMapPage";
import s from "./Home.module.scss";
import Fournisseurs from "../../pages/fournisseurs/FournisseursPage";
import Employees from "../../pages/Employees/EmployeesPage";
import Legalstatus from "../../pages/legalStatus/LegalstatusPage";
import PaymentType from "../../pages/PaymentType/PaymentTypePage";
import AdressType from "../../pages/AdressType/AdressTypePage";
import SimulationStatus from "../../pages/SimulationStatus/SimulationStatusPage";
import Prospections  from "../../pages/Prospections/ProspectionsPage"
import ConfigurationSimValues from "../../pages/configurationSimValues/configurationSimValuesPage";
import ConfigurationsFraisKilo from "../../pages/ConfigurationsFraisKilo/ConfigurationsFraisKiloPage";
import cra from "../../pages/cra/CraPage";
import contrats from "../../pages/contrats/Contrats"

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route path={["/","/home"]} exact component={Charts}/>
            <Route path="/home/configuration/StatutJuridique" exact component={Legalstatus} />
            <Route path="/home/configuration/fournisseurs" exact component={Fournisseurs} />
            <Route path="/home/configuration/Employees" exact component={Employees} />
            <Route path="/home/configuration/PaymentType" exact component={PaymentType} />
            <Route path="/home/configuration/AdressType" exact component={AdressType} />
            <Route path="/home/configuration/SimulationStatus" exact component={SimulationStatus} />
            <Route path="/home/configuration/ConfigurationSimValue" exact component={ConfigurationSimValues} />
            <Route path="/home/configuration/ConfigurationsFraisKilo" exact component={ConfigurationsFraisKilo} />
            <Route path="/home/typography" exact component={Typography} />
            <Route path="/home/CRA" exact component={cra} />
            <Route path="/home/contrats" exact component={contrats} />
            <Route path="/home/tables" exact component={Tables} />
            <Route path="/home/notifications" exact component={Notifications} />
            <Route path="/home/prospections" exact component={Prospections} />
            <Route path="/home/ui-elements" exact render={() => <Redirect to={"/ui-elements/charts"} />} />
            <Route path="/home/ui-elements/charts" exact component={Charts} />
            <Route path="/home/ui-elements/icons" exact component={Icons} />
            <Route path="/home/ui-elements/maps" exact component={Maps} />
            <Route path='*' exact render={() => <Redirect to="/home" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
