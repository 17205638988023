import React, { useState, useEffect } from "react";
import { deleteContract, getContracts, loadPdfByIdApi } from "../../services/configService";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    Col,
    Row,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import showToast from "../../components/NotificationToast/toastUtils";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../actions/loading";
import './Contrats.scss';
import { FaFilePdf } from 'react-icons/fa';
import ContractDetails from "../../components/ContractDetals/ContractDetails";

const Contracts = () => {
    const [contracts, setContracts] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedContract, setSelectedContract] = useState(null);
    const dispatch = useDispatch();

    const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);
    const toggleDetailModal = () => setIsDetailModalOpen(!isDetailModalOpen);

    const fetchContracts = async () => {
        dispatch(startLoading());
        try {
            const res = await getContracts();
            setContracts(res.data);
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(stopLoading());
        }
    };

    useEffect(() => {
        fetchContracts();
    }, []);

    const handleDelete = async () => {
        const selectedIds = selectedRows.map(row => row.id);
        try {
            await deleteContract(selectedIds);
            showToast('Les contrats sélectionnés ont été supprimés avec succès.');
            setSelectedRows([]);
        } catch (error) {
            showToast('Une erreur s\'est produite lors de la suppression des contrats.', "error");
        }
        toggleDeleteModal();
        fetchContracts();
    };

    const loadPdfById = async (id, label) => {
        dispatch(startLoading());
        try {
            const res = await loadPdfByIdApi(id);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `_${label}.pdf`; // Nom du fichier PDF
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            showToast('Le fichier PDF est téléchargé.');
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(stopLoading());
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRows([...selectedRows, row]);
        } else {
            setSelectedRows(selectedRows.filter(selectedRow => selectedRow.id !== row.id));
        }
    };

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) {
            setSelectedRows(rows);
        } else {
            setSelectedRows([]);
        }
    };

    const columns = [
        {
            dataField: 'label',
            text: 'Label',
            sort: true,
            formatter: (cell) => (cell.length > 25 ? `${cell.substring(0, 25)}...` : cell) // Limite à 25 caractères
        },
        {
            dataField: 'beginDate',
            text: 'Date de début',
            sort: true,
            formatter: (cell) => new Date(cell).toISOString().split('T')[0] // Format YYYY-MM-DD
        },
        {
            dataField: 'endDate',
            text: 'Date de fin',
            sort: true,
            formatter: (cell) => new Date(cell).toISOString().split('T')[0] // Format YYYY-MM-DD
        },
        {
            dataField: 'valid',
            text: 'Validité',
            sort: true,
            formatter: (cell) => (cell ? 'Oui' : 'Non') // Affiche "Oui" ou "Non" pour validité
        },
        {
            dataField: 'employee.firstName',
            text: 'Employé',
            formatter: (cell, row) => `${row.employee.firstName} ${row.employee.lastName}` // Combine prénom et nom
        },
        {
            dataField: 'compagny.name',
            text: 'Compagnie'
        },
        {
            dataField: 'pdfIcon',
            text: '', // Colonne sans titre
            isDummyField: true, // Pas de champ réel dans les données, sert uniquement à afficher l'icône
            formatter: (cell, row) => (
                <span onClick={() => loadPdfById(row.id , row.label)} style={{ cursor: 'pointer' }}>
                    <FaFilePdf size={20} color="red" /> {/* Icône PDF */}
                </span>
            )
        }
    ];

    const handleRowClick = (row) => {
        setSelectedContract(row);
        toggleDetailModal();
    };

    const filteredContracts = contracts.filter(contract =>
        contract.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Row className="mb-4">
            <Col>
                <Widget>
                    <Input
                        type="text"
                        placeholder="Rechercher un contrat..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="mb-3"
                    />
                    <div className="button-container mb-1 mt-2">
                        {selectedRows.length >= 1 && (
                            <Button color="danger" onClick={toggleDeleteModal}>
                                Supprimer <i className="eva eva-trash-2-outline icon" />
                            </Button>
                        )}
                    </div>
                    <BootstrapTable
                        keyField="id"
                        data={filteredContracts}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10, hideSizePerPage: true })}
                        wrapperClasses="table-responsive"
                        striped
                        hover
                        condensed
                        selectRow={{
                            mode: 'checkbox',
                            onSelect: handleOnSelect,
                            onSelectAll: handleOnSelectAll
                        }}
                        bootstrap4
                        classes="table-sm"
                        rowEvents={{
                            onClick: (e, row) => handleRowClick(row) // Ajout de l'événement click pour la ligne
                        }}
                    />
                </Widget>
            </Col>
            <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Supprimer Contrat</ModalHeader>
                <ModalBody>
                    Voulez-vous vraiment supprimer les contrats sélectionnés ?
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Annuler
                    </Button>
                    <Button color="danger" onClick={handleDelete}>
                        Supprimer
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isDetailModalOpen} toggle={toggleDetailModal} className="modal-lg">
                <ModalHeader toggle={toggleDetailModal}>Détails du Contrat</ModalHeader>
                <ModalBody>
                    {selectedContract && <ContractDetails contract={selectedContract} />}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDetailModal}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>
        </Row>
    );
};

export default Contracts;
