import React from 'react';
import { Table } from 'reactstrap';

const ContractDetails = ({ contract }) => {
    return (
        <div>
            <h5>Consultant</h5>
            <Table bordered>
                <tbody>
                <tr>
                    <td><strong>Nom:</strong></td>
                    <td>{`${contract.employee.firstName} ${contract.employee.lastName}`}</td>
                </tr>
                <tr>
                    <td><strong>Email:</strong></td>
                    <td>{contract.employee.email}</td>
                </tr>
                <tr>
                    <td><strong>Téléphone:</strong></td>
                    <td>{contract.employee.phoneNumber || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Date de naissance:</strong></td>
                    <td>{new Date(contract.employee.birthDate).toLocaleDateString()}</td>
                </tr>
                <tr>
                    <td><strong>Numéro de sécurité sociale:</strong></td>
                    <td>{contract.employee.socialSecurityNumber || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Fonction:</strong></td>
                    <td>{contract.employee.duty || 'N/A'}</td>
                </tr>
                </tbody>
            </Table>

            <h5>Contrat</h5>
            <Table bordered>
                <tbody>
                <tr>
                    <td><strong>Label:</strong></td>
                    <td>{contract.label}</td>
                </tr>
                <tr>
                    <td><strong>Date de début:</strong></td>
                    <td>{new Date(contract.beginDate).toLocaleDateString()}</td>
                </tr>
                <tr>
                    <td><strong>Date de fin:</strong></td>
                    <td>{new Date(contract.endDate).toLocaleDateString()}</td>
                </tr>
                <tr>
                    <td><strong>Validité:</strong></td>
                    <td>{contract.valid ? 'Oui' : 'Non'}</td>
                </tr>
                <tr>
                    <td><strong>Prix journalier:</strong></td>
                    <td>{contract.dailyPrice} €</td>
                </tr>
                <tr>
                    <td><strong>Termes de paiement:</strong></td>
                    <td>{contract.paymentTerm || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Fichier d'accord:</strong></td>
                    <td>{contract.agreementFile ? <a href={contract.agreementFile}>Voir le fichier</a> : 'N/A'}</td>
                </tr>
                </tbody>
            </Table>

            <h5>Client</h5>
            <Table bordered>
                <tbody>
                <tr>
                    <td><strong>Nom:</strong></td>
                    <td>{contract.compagny.name}</td>
                </tr>
                <tr>
                    <td><strong>SIRET:</strong></td>
                    <td>{contract.compagny.siretNumber}</td>
                </tr>
                <tr>
                    <td><strong>TVA:</strong></td>
                    <td>{contract.compagny.tvaNumber}</td>
                </tr>
                <tr>
                    <td><strong>Date d'enregistrement:</strong></td>
                    <td>{new Date(contract.compagny.registrationDate).toLocaleDateString()}</td>
                </tr>
                <tr>
                    <td><strong>Capital social:</strong></td>
                    <td>{contract.compagny.socialCapital || 'N/A'}</td>
                </tr>
                <tr>
                    <td><strong>Site web:</strong></td>
                    <td>{contract.compagny.website ? <a href={contract.compagny.website} target="_blank"
                                                        rel="noopener noreferrer">{contract.compagny.website}</a> : 'N/A'}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default ContractDetails;
