import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {decodeJWT, logoutUser} from "../../actions/auth";
import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";
import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";

import s from "./Header.module.scss";
import "animate.css";
import { getOrganizations } from "../../services/configService";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }



  const doLogout = () => {
    props.dispatch(logoutUser());
  }
  const isConsultant = (decodeJWT(localStorage.getItem("token")).role)==="consultant";
  const role = decodeJWT(localStorage.getItem("token")).role
  console.log(isConsultant)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const selectOrganization = (organization) => {
    setSelectedOrganization(organization);
  }

  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOrganizations();
        setOrganizations(res.data);
      } catch (error) {
        console.error(error); // Log the error
        // Handle error if needed
      }
    };
    fetchData();
  }, []);
  return (
    <Navbar className={`${s.root} d-print-none`}>
      <Nav >
        {!isConsultant && <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="ml-3 compagny-selector" >
          <DropdownToggle caret>
            {selectedOrganization ? selectedOrganization.name : 'Select Organization'}
          </DropdownToggle>
          <DropdownMenu style={{ left: 0 }}>
            {organizations.map(organization => (
              <DropdownItem key={organization.id} onClick={() => selectOrganization(organization)}>
                <i className="eva eva-home-outline company-logo" />
                <p className="body-1 mb-0">{organization.name}</p>
                <p className="body-3 text-muted mb-0">{organization.adressLine1}</p>
                {organization.city}
                <p className="body-3 text-muted mb-0">{organization.country}</p>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>}
      </Nav>
      <Nav className="ml-auto">
        <Dropdown nav isOpen={menuOpen} toggle={() => toggleMenu()} className="tutorial-dropdown mr-2 mr-sm-3">
          <DropdownToggle nav>
            <div className={s.navbarBlock}>
              <i className={'eva eva-bell-outline'} />
              <div className={s.count}></div>
            </div>
          </DropdownToggle>
          <DropdownMenu right className="navbar-dropdown notifications-dropdown" style={{ width: "340px" }}>
            <DropdownItem><img src={basketIcon} alt="Basket Icon" /><span>12 new orders have arrived today</span></DropdownItem>
            <DropdownItem>
              <div>
                <div className="d-flex flex-row mb-1">
                  <img src={mariaImage} alt="Maria" className={s.mariaImage} />
                  <div className="d-flex flex-column">
                    <p className="body-3">Maria</p>
                    <p className="label muted">15 min ago</p>
                  </div>
                </div>
                <img src={notificationImage} alt="Notification Icon" className={s.notificationImage} />
                <p className="body-2 muted">It is just a simple image that can define th..</p>
              </div>
            </DropdownItem>
            <DropdownItem><img src={calendarIcon} alt="Calendar Icon" /><span>1 event has been canceled and ...</span></DropdownItem>
            <DropdownItem><img src={envelopeIcon} alt="Envelope Icon" /><span>you have 2 new messages</span></DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Dropdown isOpen={notificationsOpen} toggle={() => toggleNotifications()} nav id="basic-nav-dropdown" className="ml-3">
          <DropdownToggle nav caret className="navbar-dropdown-toggle">
            <span className="small d-none d-sm-block ml-1 mr-2 body-1">{role} User</span>
          </DropdownToggle>
          <DropdownMenu className="navbar-dropdown profile-dropdown" style={{ width: "194px" }}>
            <DropdownItem className={s.dropdownProfileItem}><ProfileIcon /><span>Profile</span></DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}><TasksIcon /><span>Tasks</span></DropdownItem>
            <DropdownItem className={s.dropdownProfileItem}><MessagesIcon /><span>Messages</span></DropdownItem>
            <NavItem>
              <NavLink onClick={() => doLogout()} href="#">
                <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1">Logout</span></button>
              </NavLink>
            </NavItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

